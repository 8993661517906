import { branding } from './branding';
import moment from 'moment';

const CONF_BASE = {
  privacityPoliceURL: 'http://www.goodwallet.com/privacy',
  termsAndConditionURL: 'http://www.goodwallet.com/terms-and-conditions',
  wordsListSize: 12,
  ASK_FOR_COMPLETED_ON_BOARDING: true,
  FIAT_DECIMAL_PLACES: 2,
  SPANISH_RSS_FEEDS_URL: 'https://es.cointelegraph.com/rss',
  ENGLISH_RSS_FEEDS_URL: 'https://cointelegraph.com/rss',
};

export const SUPPORTED_TOKENS = [
  {
    name: 'USD',
    symbol: 'dai',
    address: '0x7c0bea7d739ccbd02f0b7f10c2ec3a890dff94af',
    compound: '0x42a628e0c5F3767930097B34b08dCF77e78e4F2B',
    decimals: 18,
  },
  {
    decimals: 18,
    name: 'USD',
    symbol: 'eth',
    compound: '0x42a628e0c5F3767930097B34b08dCF77e78e4F2B',
  },
];

let CONF;
let LOGGER;
if (process.env.NODE_ENV !== 'development') {
  // Production / Stagging.
  CONF = Object.assign({
    apiUrl: branding.apiUrl,
    showPin: true,
    useBadRandom: false,
  }, CONF_BASE);
  LOGGER = {
    // eslint-disable-next-line no-unused-vars
    log(...args) { },
    // eslint-disable-next-line no-unused-vars
    warn(...args) { },
    // eslint-disable-next-line no-unused-vars
    error(...args) {
      console.error(...args);
    },
  };
  // Configure the library
  console.log('----------------> STARTING IN PRODUCTION', CONF.apiUrl);
} else {
  // Development.
  CONF = Object.assign(
    {
      apiUrl: 'http://localhost:8000',
      showPin: false,
      useBadRandom: true,
    },
    CONF_BASE
  );
  LOGGER = {
    log(...args) {
      console.log(...args);
    },
    warn(...args) {
      console.warn(...args);
    },
    error(...args) {
      console.error(...args);
    },
  };
  LOGGER.log('----------------> STARTING IN DEVELOPMENT', CONF.apiUrl);
}

export function conf(key) {
  if (key in CONF) {
    return CONF[key];
  }
  throw new Error(`INVALID CONFIGURATION KEY "${key}"`);
}

export const logger = (function () {
  return LOGGER;
})();

const diffTime = (from, to) => { 
  const diff = moment(from).diff(to, 'days');
  return diff > 0 ? diff : 0;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getCarbonByTime(investments, targetTime) {
  const investmentCarbon = investments.map(
    inv => {
      let previousTimestamp = 0;
      const investmentCarbons = []
      
      if(inv.changes.length === 1) {
        return inv.amount * inv.changes[0].newValue * diffTime(targetTime, inv.timestamp);
      }

      let ch;
      for (ch of inv.changes) {
        if(ch.timestamp < inv.timestamp)
        if (ch.timestamp > targetTime) {
          investmentCarbons.push(diffTime(targetTime, previousTimestamp) * ch.oldValue);
          break;
        } else {
          let isFirst = ch.timestamp < inv.timestamp;
          if (isFirst) investmentCarbons.push(diffTime(targetTime, inv.timestamp) * ch.newValue)
          else {
            if (targetTime > ch.timestamp) {
              investmentCarbons.push(diffTime(targetTime, ch.timestamp) * ch.newValue);
            } else {
              investmentCarbons.push(diffTime(ch.timestamp, previousTimestamp) * ch.newValue)
            }
          }
          previousTimestamp = ch.timestamp;
        }
      }
      const totalInvestmentCarbon = inv.amount * investmentCarbons.reduce((a, b) => a + b);
      return totalInvestmentCarbon;
    }
  )
  const totalCarbon = investmentCarbon.reduce((a, b) => a + b);
  return totalCarbon;
}

export function getInvestmentByTime(investments, targetTime) {
  let totalInvestment = 0;
  for(let inv of investments) {
    if(inv.timestamp > targetTime) break;
    else totalInvestment = totalInvestment + Number(inv.amount);
  }
  return totalInvestment;
}

export * from './branding';
