import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './styles.scss';

export const EarthCard = ({ children, className, style }) => {
  return <div className={cn('EarthCard', className)} style={style}>{children}</div>;
};

EarthCard.defaultProps = {
  children: null,
  className: null,
};

EarthCard.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string,
};
