import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

export const EarthText = ({
  size,
  fontStyle,
  children,
  className,
  color,
  ...rest
}) => {
  const textStyle = `Text Text__${size} Text__${fontStyle}`;
  return (
    <p className={cn(textStyle, className, color)} style={{ color }} {...rest}>
      {children}
    </p>
  );
};

EarthText.defaultProps = {
  size: 'medium',
  fontStyle: 'normal',
  className: null,
  color: undefined
};

EarthText.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),
  fontStyle: PropTypes.oneOf(['light', 'normal', 'bold']),
  color: PropTypes.string,
  className: PropTypes.string,
};
