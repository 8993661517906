import React, { Component } from 'react';
import { branding } from '../../../utils/branding';
import './styles.scss';
import { connect } from 'react-redux';
import { Row, Col } from 'shards-react';
import { balances, users } from '../../../controllers/requests';
import { EarthActivityCard, EarthBalance, EarthCard, EarthText, EarthWalletCake } from '../../../components';
import MetricOverview from '../../../components/Charts/MetricOverview';

class EarthDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { equityTokens: [], rewardsTokens: [], activity: [] }
  }

  componentDidMount() {
    this.setState({ loadingEquity: true });
    users.getActivity().then(activity => this.setState({ activity }));
    balances.getBalances().then(equityTokens => this.setState({ loadingEquity: false, equityTokens }))
  }

  render() {
    const { history, balance } = this.props;
    const { equityTokens, rewardsTokens, loadingEquity, activity } = this.state;
    return (
      <div className="EarthDashboard">
        <div className='row' style={{ marginTop: -20 }}>
          <div className='col-12 col-xl-8 mt-4'>
            <EarthBalance
              balances={[{ label: 'Balance', amount: balance }, { label: 'APR', amount: '%6', color: 'green' }]}
              className="balance h-100"
            />
          </div>
          <div className='col-12 col-xl-4 mt-4'>
            <EarthCard>
              <div className='row'>
                <div className='col-6' onClick={() => history.push('/rewards')}>
                  <EarthCard className='hover-opacity' style={{ textAlign: 'center' }}>
                    <img src={require('../../../assets/images/store.png')} height={70} alt='icon' />
                    <EarthText fontStyle='bold' size='small'>Trade Rewards</EarthText>
                  </EarthCard>
                </div>
                <div className='col-6' onClick={() => history.push('/projects/market')}>
                  <EarthCard className='hover-opacity' style={{ textAlign: 'center' }}>
                    <img src={require('../../../assets/images/projects.png')} height={70} alt='icon' />
                    <EarthText fontStyle='bold' size='small'>Trade Projects</EarthText>
                  </EarthCard>
                </div>
              </div>
            </EarthCard>
          </div>
        </div>
        <EarthCard className="send-receive-container">
          <Row>
            <Col>
              <EarthWalletCake
                tokens={equityTokens} size={10} loading={loadingEquity}
                title={branding.equityWallet} navigate={() => history.push('/rewards')}
              />
            </Col>
            <Col>
              <EarthWalletCake
                tokens={rewardsTokens} size={8}
                title={branding.rewardsWallet} navigate={() => history.push('/rewards')}
              />
            </Col>
          </Row>
        </EarthCard>
        <EarthCard style={{ marginTop: 20 }}>
          <EarthText size='xlarge' fontStyle='bold' onClick={() => history.push('/impact')} style={{ textAlign: 'center' }}>
            Your impact
          </EarthText>
          <MetricOverview />
        </EarthCard>
        <div className="row activity">
          <div className="col-xl-12 mb-2">
            <h4 className="title-copy-15">Your Activity</h4>
            <EarthActivityCard activities={activity} />
          </div>
        </div>
      </div >
    )
  }
}

const mapStatetoProps = state => {
  return {
    ...state.root.user,
  };
}

export default connect(mapStatetoProps)(EarthDashboard);