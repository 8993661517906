import React from "react";
import PropTypes from "prop-types";
import Chart from "../../../utils/chart";

class Cake extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const { tokens } = this.props;
    const fullAmount = tokens.map(token => Number(token.balance)).reduce((a, b) => a + b, 0)
    const tokensWithPercentages = tokens.map(({ token, balance }) => ({ token, percentage: (fullAmount / balance) * 100 }))

    const chartData = {
      datasets: [
        {
          hoverBorderColor: "#ffffff",
          data: tokensWithPercentages.map(({ percentage }) => percentage),
          backgroundColor: [
            "rgba(0,123,255,0.9)",
            "rgba(0,123,255,0.5)",
            "rgba(0,123,255,0.3)",
            "rgba(0,123,255,0.1)",
          ]
        }
      ],
      labels: tokensWithPercentages.map(({ token }) => token)
    }
    const chartConfig = {
      type: "doughnut",
      data: chartData,
    };

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <canvas
          height="220"
          ref={this.canvasRef}
          className="blog-users-by-device m-auto"
        />
      </div>
    );
  }
}

Cake.propTypes = {
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

Cake.defaultProps = {
  title: "Users by device",
  chartData: {
    datasets: [
      {
        hoverBorderColor: "#ffffff",
        data: [25, 25, 25, 25],
        backgroundColor: [
          "rgba(0,123,255,0.9)",
          "rgba(0,123,255,0.5)",
          "rgba(0,123,255,0.3)",
          "rgba(0,123,255,0.1)",
        ]
      }
    ],
    labels: ["KGT", "FCT", "CET", "EST"]
  }
};

export default Cake;
