import React, { useState } from 'react';
import './styles.scss';
import { EarthText } from '..';
import { connect } from 'react-redux';

const currencyList = [
  {
    id: 1,
    name: 'USD',
    fullName: 'U.S. Dollar',
  },
  {
    id: 2,
    name: 'EUR',
    fullName: 'European Euro',
  },
  {
    id: 3,
    name: 'JPY',
    fullName: 'Japanese Yen',
  },
  {
    id: 4,
    name: 'GBP',
    fullName: 'British Pound',
  },
  {
    id: 5,
    name: 'CHF',
    fullName: 'Swiss Franc',
  },
  {
    id: 6,
    name: 'CAD',
    fullName: 'Canadian Dollar',
  },
];

const CurrencySelect = ({ balance, currency }) => {
  const [selectedCurrency, onCurrencySelect] = useState(0);

  return (
    <div className="EarthHeader__information_container">
      <div className="EarthHeader__information_container_amount border-left">
        <div className="EarthHeader__information_container_amount_total_balance">
          <EarthText size="xsmall" fontStyle="bold">
            Total Balance
          </EarthText>
          <EarthText size="xsmall" fontStyle="light">
            {balance} {currency.toUpperCase()}
          </EarthText>
          <div />
        </div>
      </div>

      <div className="EarthHeader__information_container_currency dropdown border-left d-none d-sm-block">
        <button type="button" id="currency-dropdown" data-toggle="dropdown">
          <EarthText size="xsmall" fontStyle="bold">
            {selectedCurrency.name}
            <i className="fas fa-caret-down ml-2" />
          </EarthText>
        </button>
        <div className="dropdown-menu" aria-labelledby="currency-dropdown">
          {currencyList &&
            currencyList.map(({ id, name }, index) => (
              <span
                className="dropdown-item"
                key={`currency-${id}`}
                onClick={() => {
                  onCurrencySelect(currencyList[index]);
                }}
              >
                <EarthText className="text" size="small" fontStyle="normal">
                  {name}
                </EarthText>
              </span>
            ))}
        </div>
      </div>
    </div>
  );
}

const mapStatetoProps = state => {
  return {
    ...state.root.user,
  };
}

export default connect(mapStatetoProps)(CurrencySelect);