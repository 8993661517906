/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { Container } from "shards-react";
import './styles.scss';
import { EarthWalletCake, EarthText, EarthCard, EarthInput } from "../../../components";
import { EarthMarketList } from './EarthMarketList';
import { EarthMarketOrderList } from './EarthMarketOrderList';
import { EarthMarketHistory } from './EarthMarketHistory'
import { BuySellForm } from './BuySellForm'
import { EarthMarketOverview } from './EarthMarketOverview';
import { balances } from '../../../controllers/requests';
import { branding } from "../../../utils";

class Projects extends React.Component {

  state = { sdg: 0, equityTokens: [], selectedMarket: '' };

  componentDidMount() {
    balances.getBalances()
      .then(equityTokens => this.setState({ equityTokens }))
  }

  onSDGSelection = () => {

  }

  renderPortfolioCard = () => (
    <EarthCard className="send-receive-container mb-2">
      <EarthWalletCake
        tokens={this.state.equityTokens} size={6}
        title={branding.equityWallet} navigate={null}
      />
    </EarthCard>
  )

  renderMarketCard = () => (
    <div className='EarthCard h-100'>
      <div className='row'>
        <div className='col-4'>
          <EarthText fontStyle='bold' className='card-title'>Market</EarthText>
        </div>
        <div className='col'>
          <EarthInput flex size='small' st />
        </div>
      </div>
      <EarthMarketList
        selectedMarket={this.state.selectedMarket}
        navigate={selectedMarket => this.setState({ selectedMarket })} 
      />
    </div>
  )

  renderMarketOverview = () => (
    <EarthMarketOverview
      market={this.state.selectedMarket}
    />
  )

  renderBuySell = () => (
    <BuySellForm
      market={this.state.selectedMarket}
    />
  )

  renderSellOrders = () => (
    <div className='EarthCard h-100'>
      <EarthText fontStyle='bold' className='card-title'>Buy orders</EarthText>
      <EarthMarketOrderList type='sell' market={this.state.selectedMarket} />
    </div>
  )

  renderBuyOrders = () => (
    <div className='EarthCard h-100'>
      <EarthText fontStyle='bold' className='card-title'>Sell orders</EarthText>
      <EarthMarketOrderList type='buy' market={this.state.selectedMarket} />
    </div>
  )

  renderMarketHistory = () => (
    <div className='EarthCard h-100'>
      <EarthText fontStyle='bold' className='card-title'>History</EarthText>
      <EarthMarketHistory market={this.state.selectedMarket} />
    </div>
  )

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <div className='row'>
          <div className="col">
            {this.renderPortfolioCard()}
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-12 col-xl-3'>
            {this.renderMarketCard()}
          </div>
          <div className='col-12 col-xl-6'>
            {this.renderMarketOverview()}
          </div>
          <div className='col-12 col-xl-3'>
            {this.renderBuySell()}
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-xl-3'>
            {this.renderSellOrders()}
          </div>
          <div className='col-12 col-xl-3'>
            {this.renderBuyOrders()}
          </div>
          <div className='col-12 col-xl-6'>
            {this.renderMarketHistory()}
          </div>
        </div>
      </Container>
    );
  }
}

export default Projects;