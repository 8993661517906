import React from "react";
import Chart from "../../../utils/chart";
import moment from "moment";

const lineColors = [
  { backgroundColor: "rgba(0,123,255,0.1)", borderColor: "rgba(0,123,255,1)" },
  { backgroundColor: "rgba(243,5,255,0.1)", borderColor: "rgba(243,5,255,1)" },
  { backgroundColor: "rgba(3,252,232,0.1)", borderColor: "rgba(3,252,232,1)" },
  { backgroundColor: "rgba(0,123,255,0.1)", borderColor: "rgba(0,123,255,1)" }
];

class UsersOverview extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.generateChart()
  }

  componentDidUpdate({ metrics: prevMetrics }) {
    const { metrics } = this.props;
    if (prevMetrics === metrics) return true;
    this.generateChart()
  }

  generateChart = () => {
    const { metrics } = this.props;

    const metricDatasets = metrics.map((metric, index) => ({
      label: metric.name,
      fill: "start",
      data: metric.data,
      backgroundColor: lineColors[index].backgroundColor,
      borderColor: lineColors[index].borderColor,
      pointBackgroundColor: "#ffffff",
      pointHoverBackgroundColor: "rgb(0,123,255)",
      borderWidth: 1.5,
      pointRadius: 0,
      pointHoverRadius: 3
    }));

    const dayInMillis = 1000 * 60 * 60 * 24;
    const chartData = {
      labels: (new Array(30)).fill().map((value, index) => moment(Date.now() - dayInMillis * (30 - index)).calendar()),
      datasets: metricDatasets
    }

    const chartOptions = {
      responsive: true,
      legend: { position: "top" },
      elements: {
        line: { tension: 0.3 },
        point: { radius: 0 }
      },
      scales: {
        xAxes: [
          {
            gridLines: false,
            ticks: {
              callback(tick, index) {
                // Jump every 7 values on the X axis labels to avoid clutter.
                return index % 7 !== 0 ? "" : tick;
              }
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
              suggestedMax: 1,
              callback(tick) {
                // Format the amounts using Ks for thousands.
                return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
              }
            }
          }
        ]
      },
      hover: { mode: "nearest", intersect: false },
      tooltips: {
        custom: false,
        mode: "nearest",
        intersect: false
      },
    };

    const BlogUsersOverview = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: chartData,
      options: chartOptions
    });

    if(!metrics.length) return BlogUsersOverview.render();

    const buoMeta = BlogUsersOverview.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[chartData.datasets[0].data.length - 1]._model.radius = 0;

    BlogUsersOverview.render();
  }

  render() {
    return (
      <canvas height="120" ref={this.canvasRef} style={{ maxWidth: "100% !important" }} />
    );
  }
}

export default UsersOverview;
