import PropTypes from 'prop-types';
import React from 'react';
import { EarthText } from '../EarthText/EarthText';
import { Button } from 'shards-react';
import './styles.scss';
import { EarthCard } from '../EarthCard/EarthCard';
import cn from 'classnames';

const EarthActivityItem = ({ type, title, description, amount, date, onEdit, onRemove }) => {
  return (
    <div className="EarthActivityItem">
      {
        onRemove &&
        <Button size="sm" outline theme="danger" onClick={onRemove} className="p-0">
          <i className='material-icons'>delete</i>
        </Button>
      }
      {
        onEdit &&
        <Button size="sm" outline theme="info" onClick={onEdit} className="p-0">
          <i className='material-icons'>edit</i>
        </Button>
      }

      <div className="container-1">
        <EarthText className="EarthActivityItem__title" size="small" fontStyle="bold">
          {title}
        </EarthText>
        <EarthText className="EarthActivityItem__description" size="xsmall">
          {description}
        </EarthText>
      </div>

      <div className="container-2">
        <EarthText className="EarthActivityItem__amount" size="small" fontStyle="bold">
          {amount}
        </EarthText>
        <EarthText className="EarthActivityItem__date" size="xsmall">
          {date}
        </EarthText>
      </div>
    </div>
  );
};

EarthActivityItem.propTypes = {
  type: PropTypes.oneOf(['active', 'inactive']).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  amount: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};


export const EarthActivityWithButtons = ({ activities, className, onEdit, onRemove }) => {
  return (
    <EarthCard className={cn('EarthActivityCard', className)}>
      {activities.map(
        ({ id, type, initial, title, description, amount, date }, index) => (
          <EarthActivityItem
            key={`eart-activity-card-${index}`}
            type={type}
            onEdit={onEdit}
            onRemove={onRemove ? () => onRemove(id) : undefined}
            initial={initial}
            title={title}
            description={description}
            amount={amount}
            date={date}
          />
        )
      )}
    </EarthCard>
  );
};

EarthActivityWithButtons.propTypes = {
  className: PropTypes.string,
  activities: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['active', 'inactive']).isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      amount: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired
  ),
};
