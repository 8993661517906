/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import { Container, Card, CardHeader, CardBody } from "shards-react";
import { EarthActivityWithButtons } from '../../../components';
import { users } from '../../../controllers/requests';
import Loader from 'react-loader-spinner';
import moment from "moment";
import { toast } from "react-toastify";

class Configuration extends React.Component {
  state = { loading: false, activities: [] };
  
  async componentDidMount() {
    this.setState({ loading: true });
    const activities = await users.getSessions();
    const sanitizedActivities = activities.map((activity, index) => ({
      id: { index, token: activity.token },
      title: activity.sessionAgent,
      description: `Expires ${moment(activity.expires).calendar()}`,
      date: `Created ${moment(activity.created).calendar()}`
    }))
    this.setState({ loading: false, activities: sanitizedActivities});
  }

  onExpireSession = async id => {
    const currentActivity = this.state.activities;
    currentActivity.splice(id.index, 1);
    this.setState({ activities: currentActivity });
    const res = await users.expireSession(id.token)
    if(!res.error) toast.success('Removed session');
    else toast.error(res.error);
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {this.renderSecurity()}
      </Container>
    );
  }

  renderSecurity() {
    const { activities, loading } = this.state;
    return (
      <div>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Active sessions</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
          {
                loading ?
                  <Loader
                    type="ThreeDots"
                    color="#FFF"
                    className='text-center'
                    height={20}
                    width={20}
                  />
                  :
                  <EarthActivityWithButtons onRemove={this.onExpireSession} activities={activities} />
              }
          </CardBody>
        </Card>
      </div>
    )
  }
}
export default Configuration;