import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

export const EarthInput = ({ size, flex, className, onChange, ...rest }) => {
  const inputStyles = cn({
    EarthInput: true,
    [`EarthInput__${size}`]: true,
    EarthInput__block: flex,
  });

  return <input className={cn(inputStyles, className)} onChange={onChange} {...rest} />;
};

EarthInput.defaultProps = {
  size: 'medium',
  className: null,
  flex: false,
};

EarthInput.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  flex: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
