import React, { Component } from "react";
import { Container, FormInput, Button } from "shards-react";
import { ProjectEditor, EarthModal, EarthText } from '../../../components'
import Loader from 'react-loader-spinner';
import { projects } from '../../../controllers/requests';
import { toast } from 'react-toastify';

class EarthProjectEdit extends Component {
  state = { showModal: true, loading: false, urlPath: '', editContent: {} };

  onChangeUrlPath = ({ target: { value } }) => this.setState({ urlPath: value.toLowerCase() })

  onEdit = () => {
    const { urlPath } = this.state;
    this.setState({ loading: true });
    projects.getProjectById(urlPath).then(project => {
      if (project) this.setState({ editContent: project });
      else {
        toast.error('Could not fetch project')
        this.props.history.push('/admin')
      }
      this.setState({ showModal: false });
    });
  }

  onClose = () => this.props.history.push('/admin');

  render() {
    const { showModal, loading, urlPath, editContent } = this.state;
    const { history } = this.props;

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {
          !showModal
          &&
          <ProjectEditor editContent={editContent}
            onSubmit={async project => {
              const res = await projects.updateProject(project);
              if (res) {
                toast.success('Project created');
                history.push('/admin');
              } else toast.error('Error while creating project');
            }}
          />}
        <EarthModal
          onClose={this.onClose}
          show={showModal}
          style={{ textAlign: 'center' }}
        >
          <EarthText
            size="xsmall"
            fontStyle="bold"
            className="EarthKyc__title_text text-center mb-1"
          >
            Insert project ID
          </EarthText>
          <FormInput
            value={urlPath}
            onChange={this.onChangeUrlPath}
            className='mb-2'
          />
          {
            loading ?
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                className='text-center'
                height={50}
                width={50}
                timeout={20000}
              />
              :
              <Button className="EarthKyc__modal_button centered text-bold mt-1 w-100"
                onClick={this.onEdit}
              >
                Edit
            </Button>
          }
        </EarthModal>
      </Container>
    )
  }
}

export default EarthProjectEdit;
