import React from "react";
import { EarthText } from '../../components';
import { connect } from 'react-redux';
import './styles.scss';

const counter = 0;

const Notifications = () => (
  <div className="EarthHeader__information_container_notification border-left">
    <button
      type="button"
      id="notification-dropdown"
      data-toggle="dropdown"
    >
      <i className="material-icons">notifications</i>
      {Boolean(counter) && <span className="counter">{counter}</span>}
    </button>

    <div
      className="dropdown-menu dropdown-menu-right"
      aria-labelledby="currency-dropdown"
    >
      {/* <span className="dropdown-item">
        <div className="notification-item">
          <i className="fas fa-bell notification-icon"></i>
          <div className="text-container">
            <span className="title">ANALYTICS</span>
            <span className="description">
              Your website's active users count increased by 28% in the
              last week. Great job!
            </span>
          </div>
        </div>
      </span> */}
      <span className="dropdown-item">
        <div className="notification-item">
          <EarthText>View all Notifications</EarthText>
        </div>
      </span>
    </div>
  </div>
)

const mapStatetoProps = state => {
  return {
    earthName: state.root.user.earthName,
  };
}

export default connect(mapStatetoProps)(Notifications);