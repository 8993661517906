import React from 'react';
import { EarthText, EarthModal, EarthButton } from '..';
import { connect } from 'react-redux';
import './styles.scss';
import { updateChoosenFiat, setCurrencyModal } from '../../store/actions/root';

const currencyList = [
  {
    id: 1,
    name: 'USD',
    fullName: 'U.S. Dollar',
  },
  {
    id: 2,
    name: 'EUR',
    fullName: 'European Euro',
  },
  {
    id: 3,
    name: 'JPY',
    fullName: 'Japanese Yen',
  },
  {
    id: 4,
    name: 'GBP',
    fullName: 'British Pound',
  },
  {
    id: 5,
    name: 'CHF',
    fullName: 'Swiss Franc',
  },
  {
    id: 6,
    name: 'CAD',
    fullName: 'Canadian Dollar',
  },
];

const CurrencySelect = ({ currencyModal, setCurrencySelect }) => (
  <EarthModal
    show={currencyModal}
    onClose={() => setCurrencyModal(false)}>
    <EarthText className="text-center mb-4" size="xlarge" fontStyle="bold">Select a Currency</EarthText>

    {currencyList &&
      currencyList.map(({ id, name }, index) => (
        <span
          className="dropdown-item modal-currency-item"
          key={`currency-${id}`}
          onClick={() => {
            setCurrencySelect(index);
          }}
        >
          <EarthText className="p-2 text text-center" size="small" fontStyle="normal">
            {name}
          </EarthText>
        </span>
      ))}

    <EarthButton size="large" color="primary" flex onClick={() => setCurrencyModal(false)}>
      Confirm
    </EarthButton>
  </EarthModal>
);

const mapStatetoProps = state => {
  return {
    ...state.root.user,
  };
}
const mapDispatchToProps = dispatch => ({
  updateChoosenFiat: state => dispatch(updateChoosenFiat(state)),
  setCurrencyModal: state => dispatch(setCurrencyModal(state))
});

export default connect(mapStatetoProps, mapDispatchToProps)(CurrencySelect);
