/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import Loader from 'react-loader-spinner'
import { Container, Row } from "shards-react";
import { EarthImageCard, EarthCard, EarthWalletCake } from "../../../components";
import { projects as request, balances } from '../../../controllers/requests';
import { SDGButtons } from '../../../components';
import './styles.scss';
import { branding } from "../../../utils";

class Projects extends React.Component {

  state = { sdgs: {}, projects: [], loadingProjects: false, loadingEquity: false, equityTokens: [] };

  componentDidMount() {
    this.setState({ loadingEquity: true, loadingProjects: true });
    request.getProjects().then(({ projects }) => this.setState({ projects, loadingProjects: false }));
    balances.getBalances().then(equityTokens => this.setState({ loadingEquity: false, equityTokens }));
  }

  render() {
    const { projects, sdgs, equityTokens, loadingEquity, loadingProjects } = this.state;
    const shouldApplyFilter = (new Set(Object.values(sdgs))).has(true);
    return (
      <Container fluid className="main-content-container">
        <EarthCard className="send-receive-container mb-5">
          <div className='row justify-content-center'>
            <div className='col-12 col-md-6'>
              <EarthWalletCake
                tokens={equityTokens} loading={loadingEquity} title={branding.equityWallet} navigate={null}
              />
            </div>
          </div>
        </EarthCard>
        <h4 className="title-copy-15">Filter by SDG</h4>
        <SDGButtons values={sdgs} onChange={sdgs => this.setState({ sdgs })} />
        <Row className='mt-2 projects-container'>
          {
            loadingProjects &&
            <div className='row justify-content-center w-100' style={{ alignContent: 'center', height: 150 }}> 
              <Loader
                className='text-center'
                type="ThreeDots"
                color="#00BFFF"
                height={40}
                width={40}
              />
            </div>
          }

          {
            !loadingProjects && projects.map((project, idx) => {
              if (shouldApplyFilter) {
                let shouldRender;
                Object.keys(project.sdgs).map(sdg => !shouldRender && (shouldRender = sdgs[sdg]))
                if (!shouldRender) return null;
              }
              return (
                <EarthImageCard
                  key={idx}
                  sdgs={project.sdgs}
                  backgroundImage={project.images[0]}
                  raisedPercentage={(project.raised / project.fundTarget) * 100}
                  description={project.smallDescription}
                  investment={project.minInvestment}
                  raised={project.raised}
                  investors={project.investors}
                  daysLeft={project.daysToGo}
                  location={project.location}
                  projectName={project.name}
                  badgeLabel={project.category}
                  finance={project.finance}
                  avatar={project.avatar}
                  navigate={() => this.props.history.push(`/project/${project.urlPath}`)}
                />
              )
            })}
        </Row>
      </Container>
    );
  }
}

export default Projects;