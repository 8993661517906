import React from 'react';
import { Progress } from 'shards-react';

export const EarthSaverLevel = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-8">
        <Progress
          theme="success"
          style={{ height: "10px" }}
          className="mb-3"
          value={40}
        />
      </div>
    </div>
  );
};