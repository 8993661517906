import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { FormInput, Button } from 'shards-react';
import { EarthLogo, EarthText } from '../../../components';
import { branding } from '../../../utils';
import './styles.scss';

export default class EarthSignUp extends Component {
  state = { earthName: '', email: '', password: '', rePassword: '', loading: false };

  onUsernameChange = ({ target: { value } }) => {
    this.setState({ earthName: value });
  }

  onEmailChange = ({ target: { value } }) => {
    this.setState({ email: value });
  }

  onPasswordChange = ({ target: { value } }) => {
    this.setState({ password: value });
  }

  onRePasswordChange = ({ target: { value } }) => {
    this.setState({ rePassword: value });
  }

  onSignUpSubmit = e => {
    e.preventDefault();
    const { earthName, email, password } = this.state;
    this.props.onSignUpSubmit(earthName, email, password);
  }

  render() {
    const { earthName, password, email, rePassword, loading } = this.state;
    return (
      <div className="card EarthRegister">
        <div className="card-body">
          <div className="p-2">
            <EarthText size="large" className="EarthLogin__title_text m-b-5 text-center ">
              Welcome to
            </EarthText>

            <EarthLogo className="EarthLogin__logo mt-3" size="large" />

            <form onSubmit={this.onSignUpSubmit} className="mt-5 text-center">
              <div className="form-group">
                <EarthText className="mb-1" fontStyle="bold">
                  Your username
                </EarthText>
                <FormInput
                  onChange={this.onUsernameChange}
                  required
                  className="form-control"
                  placeholder={"Your " + branding.username + " makes sending & receiving easy"}
                />
              </div>

              <div className="form-group">
                <EarthText className="mb-1" fontStyle="bold">
                  Your Email
                </EarthText>
                <FormInput
                  onChange={this.onEmailChange}
                  required
                  className="form-control"
                  placeholder="someone@mail.com"
                />
              </div>

              <div className="form-group">
                <EarthText className="mb-1" fontStyle="bold">
                  Pick a Password
                </EarthText>
                <FormInput
                  type="password"
                  onChange={this.onPasswordChange}
                  className="form-control"
                  placeholder="•••••••••••"
                />
                <small className="smalltext">12 characters minimun. Letters and Numbers</small>
              </div>

              <div className="form-group">
                <EarthText className="mb-1" fontStyle="bold">
                  Repeat your Password
                </EarthText>
                <FormInput
                  onChange={this.onRePasswordChange}
                  type="password"
                  name="confirm-password"
                  className="form-control"
                  placeholder="•••••••••••"
                />
              </div>

              <Button
                type="submit"
                size="large"
                className="EarthRegister__button w-100"
                disabled={!(earthName && email && password && rePassword && password === rePassword) || loading}
              >
                {
                  loading ?
                    <Loader
                      type="ThreeDots"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      timeout={20000}
                    />
                    :
                    'SIGN UP'
                }
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
