import React, { Component } from 'react';
import { EarthText, SDGButtons } from '../../../components';
import Loader from 'react-loader-spinner';
import { markets } from '../../../controllers/requests';
import { conf } from '../../../utils';
import Chart from '../../../components/Charts/Overview/Overview'
import './styles.scss';

export class EarthMarketOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, market: {}, loadingChart: false, metrics: [] };
  }

  componentDidMount() {
    const { market } = this.props;
    if (market) this.pullMarketById()
  }

  componentDidUpdate({ market: prevMarket }) {
    const { market } = this.props;
    if ((market !== prevMarket) && market) this.pullMarketById();
  }

  pullMarketById = () => {
    const { market } = this.props;
    this.setState({ loading: true, loadingChart: true });
    markets.getMarketById(market)
      .then(market => this.setState({ market, loading: false }));
    markets.getMetricsById(market)
      .then(metrics => this.setState({ metrics, loadingChart: false }));
  }

  renderNoMarket = () => (
    <div className='select-market' style={{ marginTop: '20%' }}>
      <i className='material-icons'>storefront</i>
      <p className='select-market'>Select a market to continue</p>
    </div>
  )

  renderOverview = () => {
    const { metrics, loadingChart } = this.state;
    const { name, volatibility, initialPrice, lastPrice, equityToken, avatar, sdgs } = this.state.market;
    return (
      <div>
        <div className='row mb-4' style={{ alignItems: 'center' }}>
          <div className='col-12 col-xl-4'>
            <div className='icon-name'>
              <img height={40} alt='token' src={conf('apiUrl') + '/static/images/' + avatar} />
              <div>
                <EarthText fontStyle='bold' size='xsmall'>{name}</EarthText>
                <EarthText fontStyle='bold'>{equityToken}</EarthText>
              </div>
            </div>
          </div>
          <div className='col-12 col-xl-8'>
            <div className='row'>
              <div className='col'>
                <EarthText fontStyle='bold' size='xsmall'>Price</EarthText>
                <EarthText fontStyle='bold' size='xsmall'>{initialPrice}</EarthText>
              </div>
              <div className='col'>
                <EarthText fontStyle='bold' size='xsmall'>Volatibility</EarthText>
                <EarthText fontStyle='bold' size='xsmall'>{volatibility}</EarthText>
              </div>
              <div className='col'>
                <EarthText fontStyle='bold' size='xsmall'>Last Price</EarthText>
                <EarthText fontStyle='bold' size='xsmall'>{lastPrice}</EarthText>
              </div>
              <div className='col'>
                <EarthText fontStyle='bold' size='xsmall'>Change</EarthText>
                <EarthText fontStyle='bold' size='xsmall'>{((lastPrice / initialPrice) * 100) - 100}</EarthText>
              </div>
            </div>
          </div>
        </div>
        <div className='mb-4'>
          <SDGButtons smaller onlyShow={sdgs} endToStart />
        </div>
        {loadingChart ? <Loader className='text-center' type="ThreeDots" color="#00BFFF" /> : <Chart metrics={metrics} />}
      </div>
    )
  }

  render() {
    const { market } = this.props;
    const { loading } = this.state;

    return (
      <div className='EarthCard h-100'>
        {market && (loading ? <Loader className='text-center' type="ThreeDots" color="#00BFFF" /> : this.renderOverview())}
        {!market && this.renderNoMarket()}
      </div>
    );
  }
}
