import React from "react";
import { branding } from '../../utils/branding';
import './styles.scss';

export default ({onClickMenuIcon}) => (
  <div className="EarthHeader__logo">
    <img
      src={branding.lightLogo}
      alt="big"
      height="30"
      className="d-none d-md-block"
    />
    <i
      className="d-block d-md-none material-icons menu-icon"
      onClick={onClickMenuIcon}
    >menu</i>
  </div>
)