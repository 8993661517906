import React from "react";
import Chart from "./Overview/Overview";
import { users } from '../../controllers/requests';
import { getCarbonByTime, getInvestmentByTime } from '../../utils';

export default class MetricOverview extends React.Component {
  state = { metrics: [] }

  async componentDidMount() {
    const investments = await users.getMetrics();
    const dayMilliseconds = 60 * 60 * 24 * 1000;

    const carbonPerDay = []
    for (let i = 29; i >= 0; i--) {
      carbonPerDay.push(await getCarbonByTime(investments, Date.now() - dayMilliseconds * i));
    }

    const investedPerDay = []
    for (let i = 29; i >= 0; i--) {
      investedPerDay.push(await getInvestmentByTime(investments, Date.now() - dayMilliseconds * i));
    }

    this.setState({
      metrics: [
        { name: 'Carbon', data: carbonPerDay },
        { name: 'Invested', data: investedPerDay }
      ]
    });
  }

  render() {
    const { metrics } = this.state;

    return (
      <Chart metrics={metrics} />
    );
  }
}
