import React, { Component } from 'react';
import { EarthText, EarthTabItem, EarthTabs, EarthInput, EarthButton } from '../../../components';
import { markets } from '../../../controllers/requests';
import './styles.scss';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

export class BuySellForm extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, price: '', amount: '', tab: 'Buy' };
  }

  onSubmit = action => {
    const { market } = this.props;
    const { price, amount, tab } = this.state;
    markets.postOrder(market, tab, amount, price)
      .then(res => {
        if (res) toast.success('Order submited successfully')
        else toast.error('Error while submiting order')
      });
  }

  renderForm = ({ title }) => (
    <EarthTabItem
      title={title}
      onClick={() => this.setState({ tab: title, price: '', amount: '' })}
      isActive={this.state.tab === title}
    >
      <div className="form-group">
        <EarthText className="mb-1" fontStyle="bold">
          Amount
        </EarthText>
        <EarthInput
          onChange={({ target: { value } }) => this.setState({ amount: value })}
          value={this.state.amount} flex
        />
      </div>
      <div className="form-group">
        <EarthText className="mb-1" fontStyle="bold">
          Price
        </EarthText>
        <EarthInput
          onChange={({ target: { value } }) => this.setState({ price: value })}
          value={this.state.price} flex
        />
      </div>
      <div className="form-group">
        <EarthText className="mb-1" fontStyle="bold">
          Total
        </EarthText>
        <EarthInput
          disabled={true}
          value={Number(this.state.price) * Number(this.state.amount)} flex
        />
      </div>
      <EarthButton
        type="button"
        onClick={() => this.onSubmit(title)}
        className="EarthRegister__button p-3"
        disabled={!(this.state.price && this.state.amount && this.props.market)}
        flex
      >
        {
          this.state.loading ?
            <Loader
              type="ThreeDots"
              color="#FFF"
              className='text-center'
              height={20}
              width={20}
            />
            :
            title

        }
      </EarthButton>
    </EarthTabItem>
  )

  render() {
    return (
      <div className='EarthCard h-100'>
        <EarthTabs>
          {this.renderForm({ title: 'Buy' })}
          {this.renderForm({ title: 'Sell' })}
        </EarthTabs>
      </div>
    );
  }
}