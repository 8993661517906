import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CurrencySelect from './CurrencySelect';
import Profile from './Profile';
import Notifications from './Notifications';
import SideBarButton from './SideBarButton';
import PathText from './PathText';
import './styles.scss';
import CurrencySelectModal from './CurrencySelectModal';


export class EarthHeader extends Component {
  state = { selectedCurrency: 0 }
  onCurrencySelect = selectedCurrency => this.setState({ selectedCurrency });

  render() {
    const { onClickMenuIcon } = this.props;
    const { pathname } = this.props.history.location;
    
    return (
      <div className="EarthHeader">
        <SideBarButton onClickMenuIcon={onClickMenuIcon} />
        <PathText pathname={pathname} />
        <CurrencySelect />
        <Notifications />
        <Profile triggerCurrencyModal={() => this.setState({ showCurrencyModal: true })}/>
        <CurrencySelectModal />
      </div>
    );
  }
}

EarthHeader.propTypes = {
  onClickMenuIcon: PropTypes.func.isRequired,
};