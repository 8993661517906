import React, { Component } from 'react';
import SmallStats from "../../../components/Charts/SmallStat/SmallStat";
import { users } from '../../../controllers/requests';
import { EarthBalance, EarthSaverLevel, EarthCard, EarthActivityCard } from '../../../components';
import './styles.scss';
import { branding } from '../../../utils';
import MetricOverview from '../../../components/Charts/MetricOverview';

const smallStats = [
  {
    label: "Waste avoided",
    value: "841 lbs",
    percentage: "4.7%",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "6", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(0, 184, 216, 0.1)",
        borderColor: "rgb(0, 184, 216)",
        data: [4, 2, 6, 4, 5, 3, 6]
      }
    ]
  },
  {
    label: "Compost created",
    value: "1210 lbs",
    percentage: "1.7%",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "6", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(0, 184, 216, 0.1)",
        borderColor: "rgb(0, 184, 216)",
        data: [6,5, 4, 7, 4, 2, 6]
      }
    ]
  },
  {
    label: "Water Filtered",
    value: "2,390 l",
    percentage: "4.7%",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "6", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(0, 184, 216, 0.1)",
        borderColor: "rgb(0, 184, 216)",
        data: [1, 2, 1, 3, 5, 4, 7]
      }
    ]
  },
  {
    label: "Solar Energy",
    value: "182 kw",
    percentage: "12.4",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "6", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(23,198,113,0.1)",
        borderColor: "rgb(23,198,113)",
        data: [1, 2, 3, 3, 3, 4, 4]
      }
    ]
  },
  {
    label: "People reached",
    value: "1,147",
    percentage: "3.8%",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(23,198,113,0.1)",
        borderColor: "rgb(23,198,113)",
        data: [2, 3, 3, 3, 4, 3, 3]
      }
    ]
  },
  {
    label: "Oxygen released",
    value: "621 tons",
    percentage: "2.8%",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(23,198,113,0.1)",
        borderColor: "rgb(23,198,113)",
        data: [1, 2, 3, 5, 6, 8, 6]
      }
    ]
  },
  {
    label: "Water transpiration",
    value: "382 Ml",
    percentage: "2.8%",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(23,198,113,0.1)",
        borderColor: "rgb(23,198,113)",
        data: [4, 5, 5, 4, 6, 7, 9]
      }
    ]
  }
]

class Impact extends Component {
  state = {
    height: 300,
    width: 450,
    activity: []
  }

  componentDidMount() {
    users.getActivity().then(activity => this.setState({ activity }));
  }

  render() {
    const { activity } = this.state;
    return (
      <div>
        <EarthBalance
          balances={[{ label: branding.earthRewards, amount: '14122', color: '', noSuffix: true }, { label: 'You are doing well this month!', noSuffix: true, amount: '' }, { label: 'Yield', amount: '$4250', color: 'green' }]}
          className="balance"
        >
          <EarthSaverLevel />
        </EarthBalance>

        <EarthCard style={{ marginTop: 20 }}>
          <MetricOverview/>
        </EarthCard>

        <div className='mb-4 mt-4' style={{ flexWrap: 'wrap', display: 'flex' }}>
          {smallStats.map((stats, idx) => (
            <div key={idx} style={{ flex: 1, flexGrow: 1, margin: '0.4rem', minWidth: 120 }}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </div>  
          ))}
        </div>

        <div className="row activity">
          <div className="col-xl-12 mb-2">
            <h4 className="title-copy-15">Your Activity</h4>
            <EarthActivityCard activities={activity} />
          </div>
        </div>
      </div >
    );
  }
}

export default Impact;