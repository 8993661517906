/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { Container, Card, CardHeader, CardBody } from "shards-react";
import { EarthTabs, EarthTabItem, EarthActivityWithButtons } from '../../../components';
import Select from 'react-select';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const activities = [
  {
    type: 'inactive',
    initial: 'T',
    title: "You' Transfered to Another Account",
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    amount: '-100.00 USD',
    date: 'Today',
  },
  {
    type: 'active',
    initial: 'S',
    title: 'You’ve Sold',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    amount: '1.25 USD',
    date: 'Monday',
  },
  {
    type: 'inactive',
    initial: 'B',
    title: "You've Bought",
    description: '0xaE61574221EEcA56221EEcA5621EEc74221EE21',
    amount: '-100.00 USD',
    date: 'October 19',
  },
  {
    type: 'active',
    initial: 'D',
    title: "You've Deposited to this account",
    description: '',
    amount: '220.25 USD',
    date: 'October 2',
  },
];


class Configuration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'general',
      selectedOption: null,
    }
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  swapTab = tab => this.setState({ tab });

  render() {
    const { tab } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <EarthTabs>
          <EarthTabItem
            onClick={() => this.swapTab('general')}
            icon=""
            title="General"
            isActive={tab === 'general'}
          >
            {this.renderGeneral()}
          </EarthTabItem>

          <EarthTabItem
            onClick={() => this.swapTab('security')}
            icon=""
            title="Security"
            isActive={tab === 'security'}
          >
            {this.renderSecurity()}
          </EarthTabItem>
        </EarthTabs>
      </Container>
    );
  }

  renderGeneral() {
    const { selectedOption } = this.state;
    return (
      <div>
        <div style={{ textAlign: 'center' }}>
          <p>
            OPTION 1
          </p>
          <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={options}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <p>
            OPTION 3
          </p>
          <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={options}
          />
        </div>
      </div>
    )
  }

  renderSecurity() {
    return (
      <div>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Active Users</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <EarthActivityWithButtons activities={activities} />
          </CardBody>
        </Card>
      </div>
    )
  }
}
export default Configuration;