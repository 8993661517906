import React, { Component } from 'react';
import { Row } from 'shards-react';
import { EarthText } from '../../../components';
import { markets } from '../../../controllers/requests';
import './styles.scss';

export class EarthMarketOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, orders: [] };
  }

  componentDidMount() {
    const { market } = this.props;
    if (market) this.pullOrders()
  }

  componentDidUpdate({ market: prevMarket }) {
    const { market } = this.props;
    if ((market !== prevMarket) && market) this.pullOrders()
  }

  pullOrders = () => {
    const { type, market } = this.props;
    this.setState({ loading: true });
    markets.getOrders(market, type)
      .then(orders => this.setState({ orders: orders || [], loading: false }));
  }

  render() {
    const { orders } = this.state;
    return (
      <div className="text-container">
        <li className='token-element-no-avatar'>
          <Row style={{ alignItems: 'center' }}>
            <div className='col'>
              <EarthText size='xsmall' fontStyle='bold'>
                Token
                </EarthText>
            </div>
            <div className='col'>
              <EarthText size='xsmall'>
                Amount
                </EarthText>
            </div>
            <div className='col'>
              <EarthText size='xsmall' fontStyle='bold'>
                Price
                </EarthText>
            </div>
          </Row>
        </li>
        <ul className='market-list'>
          {
            orders.map(
              ({ equityToken, price, amount, id }) => (
                <li className='token-element-no-avatar' onClick={() => this.props.navigate(id)}>
                  <Row style={{ alignItems: 'center' }}>
                    <div className='col'>
                      <EarthText size='xsmall' fontStyle='bold'>
                        {equityToken}
                      </EarthText>
                    </div>
                    <div className='col'>
                      <EarthText size='xsmall'>
                        {amount}
                      </EarthText>
                    </div>
                    <div className='col'>
                      <EarthText size='xsmall' fontStyle='bold'>
                        {price}
                      </EarthText>
                    </div>
                  </Row>
                </li>
              )
            )
          }
        </ul>
      </div>
    );
  }
}