import React from "react";
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux';
import { users, saveToken } from '../controllers/requests';
import { updateUser } from '../store/actions/root';
import { branding } from '../utils';

class AppLoading extends React.Component {

  async componentDidMount() {
    const hashPw = await sessionStorage.getItem('hashPw');
    const authToken = await sessionStorage.getItem('authToken');
    saveToken(authToken);
    if (hashPw && authToken) {
      const user = await users.getSelf()
      if(!user) {
        sessionStorage.clear();
        this.props.onLoadingComplete(false);
      }
      this.props.updateUser(user);
      this.props.onLoadingComplete(true);
    } else {
      sessionStorage.clear();
      this.props.onLoadingComplete(false);
    }
  }

  render() {
    return (
      <div className='row justify-content-center' style={{ height: '100vh' }}>
        <div className='col-10 col-md-6 col-xl-4 my-auto text-center'>
          <img
            src={branding.darkLogo}
            className='mb-4'
            alt='logo'
            width="100%"
          />
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
          />
        </div>
      </div>
    )
  }
};


const mapDispatchtoProps = dispatch => {
  return {
    updateUser: state => dispatch(updateUser(state)),
  };
}

export default connect(null, mapDispatchtoProps)(AppLoading);