import React, { Component } from 'react';
import { Row } from 'shards-react';
import { EarthText } from '../../components';
import { conf } from '../../utils';
import './style.scss';

export class EarthTokenWallet extends Component {
  render() {
    const { tokens } = this.props;
    return (
      <div className="text-container">
        <ul className='token-list scrollbar'>
          {
            tokens.map(
              ({ avatar, name, token, balance, id }, index) => (
                <li className='token-element' key={index} onClick={() => this.props.navigate(id)}>
                  <Row style={{ alignItems: 'center' }}>
                    <div className='col col-2 col-xl-2 col-sm-2'>
                      <span
                        alt='asd'
                        style={{ width: 10, height: 10, backgroundImage: `url('${conf('apiUrl') + '/static/images/'+avatar}')` }}
                        className="card-post__author-avatar card-post__author-avatar--xsmall"
                      />
                    </div>
                    <div className='col col-7'>
                      <EarthText size='xsmall'>
                        {name}  {token}
                      </EarthText>
                    </div>
                    <div className='col col-xl-3 col-sm-2'>
                      <EarthText size='xsmall' fontStyle='bold'>
                        {balance}
                      </EarthText>
                    </div>
                  </Row>
                </li>
              )
            )
          }
        </ul>
      </div>
    );
  }
}