/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button,
} from "shards-react";
import { branding } from "../../../utils";
import { EarthCard, EarthWalletCake } from '../../../components';

// Second list of posts.
const PostsListTwo = [
  {
    backgroundImage: require("../../../assets/images/placeholders/content-management/5.jpeg"),
    category: "Travel",
    categoryTheme: "info",
    author: "Anna Ken",
    authorAvatar: require("../../../assets/images/placeholders/avatars/0.jpg"),
    title:
      "5% OFF on your next hotel stay",
    body:
      "Get discounts on these hotels: Comfort Kis...",
    date: ""
  },
  {
    backgroundImage: require("../../../assets/images/placeholders/content-management/regenerative.jpg"),
    category: "Regenerative",
    categoryTheme: "dark",
    author: "John James",
    authorAvatar: require("../../../assets/images/placeholders/avatars/1.jpg"),
    title:
      "70% OFF regeneratively grown protein",
    body:
      "Regeneratively grazed and grown food sequesters carbon into the soil!",
    date: ""
  }
]

// Third list of posts.
const PostsListThree = [
  {
    author: "SUPERMARKET",
    authorAvatar: require("../../../assets/images/placeholders/avatars/1.jpg"),
    title: "10% OFF on your next groceries cart",
    body: "Get a discount from the following stores ...",
    date: "29 February 2019"
  },
  {
    author: "CLOTHING STORE",
    authorAvatar: require("../../../assets/images/placeholders/avatars/2.jpg"),
    title: "10% OFF on clothes",
    body: "Get a discount from the following stores ...",
    date: "29 February 2019"
  },
  {
    author: "FURNITURE OUTLET",
    authorAvatar: require("../../../assets/images/placeholders/avatars/3.jpg"),
    title: "10% OFF on furniture",
    body: "Get a discount from the following stores ...",
    date: "29 February 2019"
  }
]

const PostsListFour = [
  {
    backgroundImage: require("../../../assets/images/placeholders/content-management/7.jpeg"),
    author: "Alene Trenton",
    authorUrl: "#",
    category: "News",
    categoryUrl: "#",
    title: "FREE airport lounge pass",
    body: "",
    date: "29 February 2019"
  },
  {
    backgroundImage: require("../../../assets/images/placeholders/content-management/8.jpeg"),
    author: "Chris Jamie",
    authorUrl: "#",
    category: "News",
    categoryUrl: "#",
    title: "FREE coffee at participating retailers",
    body: "",
    date: "29 February 2019"
  },
  {
    backgroundImage: require("../../../assets/images/placeholders/content-management/9.jpeg"),
    author: "Monica Jordan",
    authorUrl: "#",
    category: "News",
    categoryUrl: "#",
    title: "FREE public transport ride",
    body: "",
    date: "29 February 2019"
  },
  {
    backgroundImage: require("../../../assets/images/placeholders/content-management/10.jpeg"),
    author: "Monica Jordan",
    authorUrl: "#",
    category: "News",
    categoryUrl: "#",
    title: "FREE compost for your garden",
    body: "",
    date: "29 February 2019"
  }
]

class Rewards extends React.Component {

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <EarthCard className="send-receive-container mb-5">
          <EarthWalletCake
            title='Rewards Tokens' tokens={[]} navigate={null} 
          />
        </EarthCard>
        <h4 className="title-copy-15 mb-4">Where can I spend my {branding.earthRewards} ?</h4>
        <Row>
          {PostsListTwo.map((post, idx) => (
            <Col lg="6" sm="12" className="mb-4" key={idx}>
              <Card small className="card-post card-post--aside card-post--1">
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url('${post.backgroundImage}')` }}
                >
                  <Badge
                    pill
                    className={`card-post__category bg-${post.categoryTheme}`}
                  >
                    {post.category}
                  </Badge>
                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{ backgroundImage: `url('${post.authorAvatar}')` }}
                    >

                    </a>
                  </div>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <a className="text-fiord-blue" href="#">
                      {post.title}
                    </a>
                  </h5>
                  <p className="card-text d-inline-block mb-3">{post.body}</p>
                  <p className='card-title'>{post.location}</p>
                  <span className="text-muted">{post.date}</span>
                  <br />
                  <Button theme="info" className="mb-2 mr-1">
                    Redeem
                  </Button>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        <Row>
          {PostsListThree.map((post, idx) => (
            <Col lg="4" key={idx}>
              <Card small className="card-post mb-4">
                <CardBody>
                  <h5 className="card-title">{post.title}</h5>
                  <p className="card-text text-muted">{post.body}</p>
                  <p className='card-title'>{post.location}</p>
                </CardBody>
                <CardFooter className="border-top d-flex">
                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{ backgroundImage: `url('${post.authorAvatar}')` }}
                    >

                    </a>
                    <div className="d-flex flex-column justify-content-center ml-3">
                      <span className="card-post__author-name">
                        {post.author}
                      </span>
                      <small className="text-muted">{post.date}</small>
                    </div>

                  </div>
                  <div className="my-auto ml-auto">

                  </div>
                  <Button theme="info" className="mb-2 mr-1">
                    Redeem
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          ))}
        </Row>

        <Row>
          {PostsListFour.map((post, idx) => (
            <Col lg="3" md="6" sm="12" className="mb-4" key={idx}>
              <Card small className="card-post h-100">
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url('${post.backgroundImage}')` }}
                />
                <CardBody>
                  <h5 className="card-title">
                    <a className="text-fiord-blue" href="#">
                      {post.title}
                    </a>
                  </h5>
                  <p className="card-text">{post.body}</p>
                  <p className='card-title'>{post.location}</p>
                </CardBody>
                <Col size={4}>
                  <Button theme="info" className="mb-2 mr-1">
                    Redeem
                    </Button>
                </Col>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default Rewards;