import React, { Component } from 'react';
import { EarthText, EarthTokenWallet } from '../../components';
import Cake from '../Charts/Cake/Cake';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

class WalletCake extends Component {
  render() {
    const { title, navigate, tokens, size, loading } = this.props;
    return (
      <div className="App">
        <div className='row justify-content-center'>
          <EarthText size='large' fontStyle='bold' className='mb-4 mr-3'>{title}</EarthText>
          <img alt='' src={require('../../assets/images/wallet.png')} height={30} />
        </div>
        {
          loading ?
          <Loader
            className='text-center'
            type="ThreeDots"
            color="#00BFFF"
            height={20}
            width={20}
          />
          :          
          !tokens.length ?
            <div style={{ textAlign: 'center' }}>
              <img
                alt=''
                height={70}
                src={require('../../assets/images/empty.png')}
              />
              <Link to='/projects'>
                <EarthText size='large' fontStyle='bold'>
                  Your wallet is ready for investments
                </EarthText>
              </Link>
            </div>
            :
            <div className='row justify-content-center'>
              <div className={`col-12 col-md-${size || 12}`}>
                <div className='row justify-content-center'>
                  <div className='col'>
                    <EarthTokenWallet navigate={navigate} tokens={tokens} />
                  </div>
                  <div className='col'>
                    <Cake tokens={tokens} />
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    appLoading: state.root.loading,
    locked: state.root.locked
  };
}

export const EarthWalletCake = connect(mapStatetoProps)(WalletCake);