import { capitalize } from '../../utils';
import React from "react";
import './styles.scss';

const slashPattern = new RegExp('/', 'g');

export default ({ pathname }) => (
  <p className="EarthHeader__page_title" style={{ textTransform: 'capitalize' }}>
    {capitalize(pathname.slice(1).replace(slashPattern, ' '))}
  </p>
)

