import React from "react";
import { Container, Row, Col } from "shards-react";
import { UserDetails, UserAccountDetails } from "../../../components";

const Profile = () => (
  <Container fluid className="main-content-container px-4">
    <Row>
      <Col lg="4">
        <UserDetails />
      </Col>
      <Col lg="8">
        <UserAccountDetails />
      </Col>
    </Row>
  </Container>
);

export default Profile;
