export const branding = {
  brandName: 'Liquid Token', 
  earthRewards: 'Eco Rewards',
  username: 'Username',
  lightLogo: require('./branding/light.png'),
  darkLogo: require('./branding/dark.png'),
  webPage: 'liquidtoken.net',
  apiUrl: 'https://api.liquidtoken.net',
  equityWallet: 'Account',
  rewardsWallet: 'Rewards'
}
