import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { EarthFooter, EarthHeader, EarthSidebar, EarthAlertBox } from '../../components';
import EarthDashboard from './EarthDashboard/EarthDashboard';
import EarthProjects from './EarthProjects/EarthProjects';
import EarthProjectCreate from './EarthProjectCreate/EarthProjectCreate';
import EarthProjectEdit from './EarthProjectCreate/EarthProjectEdit';
import EarthProjectsMarket from './EarthProjectsMarket/EarthProjectsMarket';
import EarthProjectView from './EarthProjectView/EarthProjectView';
import EarthSettings from './EarthSettings/EarthSettings';
import EarthSecurity from './EarthSecurity/EarthSecurity';
import EarthProfile from './EarthProfile/EarthProfile';
import EarthRewards from './EarthRewards/EarthRewards';
import AlertsManagement from './AlertsManagement';
import Impact from './Impact/Impact';
import Admin from './Admin/Admin'
import './styles.scss';

const EarthMainContainer = props => {
  const [showSidebar, setSidebarShow] = useState(false);

  const onClickMenuIcon = () => {
    setSidebarShow(!showSidebar);
  };

  return (
    <div id="wrapper">
      <EarthHeader
        onClickMenuIcon={onClickMenuIcon}
        {...props}
      />
      
      <EarthSidebar toggleShow={showSidebar} onClickMenuIcon={() => setSidebarShow(false)} {...props} />

      <div className="EarthMain_container">
        <EarthAlertBox {...props} />
        <Route path="/dashboard" exact render={props => <EarthDashboard {...props} />} />
        
        <Route path="/projects" exact component={EarthProjects} />
        <Route path="/projects/market" exact component={EarthProjectsMarket} />
        <Route path="/project/:urlPath" component={EarthProjectView} />
        
        <Route path="/impact" exact component={Impact} />
        
        <Route path="/admin" exact component={Admin} />
        <Route path="/admin/alerts" exact component={AlertsManagement} />
        <Route path="/admin/project/create" exact {...props} component={EarthProjectCreate} />
        <Route path="/admin/project/edit" exact {...props} component={EarthProjectEdit} />

        <Route path="/profile" exact component={EarthProfile} />
        
        <Route path="/settings" exact component={EarthSettings} />
        <Route path="/settings/security" exact component={EarthSecurity} />
        
        <Route path="/rewards" exact component={EarthRewards} />
        
        <Redirect from='/' to='/dashboard' />
        <EarthFooter />
      </div>
    </div>
  );
};

EarthMainContainer.propTypes = {
  history: PropTypes.object.isRequired,
};

export const EarthMain = withRouter(EarthMainContainer);
