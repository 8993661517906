import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import EarthApp from './containers/EarthApp/EarthApp';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import './assets/shards-dashboards.1.1.0.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import "react-image-gallery/styles/css/image-gallery.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './app.scss';

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <EarthApp />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
