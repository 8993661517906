import React, { Component } from 'react';
import { saveToken, users} from '../../../controllers/requests';
import { FormInput, Button } from 'shards-react';
import { sha3_256 } from 'js-sha3';
import { EarthLogo, EarthText } from '../../../components';
import { branding } from '../../../utils';
import './styles.scss';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

export default class EarthSignIn extends Component {
  state = { earthName: '', password: '', loading: false };

  onUsernameChange = ({ target: { value } }) => this.setState({ earthName: value });
  onPasswordChange = ({ target: { value } }) => this.setState({ password: value });

  onSignInSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    const { earthName, password } = this.state;
    const { token, active, kyc } = await users.signIn(earthName, password);
    this.setState({ loading: false });
    saveToken(token);
    if (token) {
      if (active) {
        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('hashPw', sha3_256(password).toString('hex'));
      }
      toast.success('Sign in succesful!');
      this.props.onSignInSubmit({ loggedIn: true, active, kyc });
    } else toast.error('Failed to sign in!');
  }

  render() {
    const { earthName, password, loading } = this.state;
    return (
      <div className="card EarthLogin">
        <div className="card-body">
          <div className="p-2">
            <EarthText size="large" className="EarthLogin__title_text m-b-5 text-center ">
              Welcome to
            </EarthText>

            <EarthLogo className="EarthLogin__logo mt-3" size="large" />

            <form onSubmit={this.onSignInSubmit} className="mt-5" style={{ textAlign: 'center' }}>
              <FormInput
                onChange={this.onUsernameChange}
                value={earthName}
                className="mr-2 mt-2 mb-4"
                placeholder={"Your " + branding.username + " or Email"}
                required
              />

              <FormInput
                onChange={this.onPasswordChange}
                value={password}
                type="password"
                className="mr-2 mt-2 mb-4"
                placeholder="Your Password"
                required
              />

              <Button
                className="EarthLogin__button"
                type='submit'
                disabled={!(earthName && password) || loading}
              >
                {
                  loading ?
                    <Loader
                      type="ThreeDots"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      timeout={20000}
                    />
                    :
                    'SIGN IN'
                }
              </Button>

              <EarthText size="xsmall" className="EarthLogin__forgot_password_text text-center mt-4">
                Forgot your {branding.username} ?
              </EarthText>
            </form>
          </div>
        </div>
      </div>
    );
  }
}